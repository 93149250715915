<template>
	<div class="u-topic__head">
		<AtomsBaseHeadline class="u-topic__headline fs-10 lh-sm text-center" heading="h2">{{ name }}</AtomsBaseHeadline>
		<AtomsParagraph class="text-center my-2" :text="description"></AtomsParagraph>

		<ClientOnly>
			<AtomsSelectableTags
				class="u-my-topics-form__topics"
				name="tags"
				:tagList="tagList"
				v-model:selected-tags="selectedTopics"
			></AtomsSelectableTags>
			<template #fallback>
				<AtomsSelectableTags class="u-my-topics-form__topics" name="tags" :tagList="tagList"></AtomsSelectableTags>
			</template>
		</ClientOnly>
	</div>

	<OrganismsTeaserGroup :teasers="articles" layout="8" class="mt-10"></OrganismsTeaserGroup>
</template>

<script lang="ts" setup>
	definePageMeta({
		layout: "full",
	});

	const props = withDefaults(defineProps<{}>(), {});

	useTrailingSlashRedirect();

	const { data, error } = await useFetch(
		"/api/" +
			usePublication() +
			"/retresco/topics/" +
			useRoute()
				.path.toLowerCase()
				.replace(/^\/thema\//, "")
				.split("/")[0],
	);

	const name = (data as any).value.name;
	const id = (data as any).value.id;
	const url = (data as any).value.url;
	const description = (data as any).value.description;
	const articles = (data as any).value.articles;

	const tagList = [{ title: "Folge ich<>Thema folgen", key: id }];
	const selectedTopics: Ref<any> = ref([]);

	//Redirect if topic was found but url is different
	if (url && useRoute().path !== url) {
		navigateTo(useRoute().fullPath.replace(useRoute().path, url), { redirectCode: 301 });
	}

	onMounted(async () => {
		if (useUserIsLoggedIn()) {
			const favouriteTopicsResponse = (await $fetch(`/api/no-cache/user/user-favourites/favourite-topics`)) || [];
			selectedTopics.value = favouriteTopicsResponse.map((t: any) => t.topicId).filter((t: any) => t === id);
		}
	});

	watch(selectedTopics, (newVal, oldVal) => {
		const topicConfig = [{ topicId: id, topic: name }];

		if (!useUserIsLoggedIn()) {
			if (selectedTopics.value?.length) {
				useClientStore().rememberTopicForLogin = topicConfig[0];
				document.body.dispatchEvent(new CustomEvent("show-login-required-modal", {
					detail: {
						messageType: "topic"
					}
				}));
				// Reset selection
				selectedTopics.value = [];
			}

			return;
		}

		if (oldVal === null) return;

		if (newVal.includes(id)) {
			useSetTopicFollowState(id, topicConfig, "add");
		} else {
			useSetTopicFollowState(id, topicConfig, "delete");
		}
	});
	useBaseMetaHead(name);
	await useBreadcrumb([
		{
			label: "Alle Themen",
			url: "/thema",
		},
		{
			label: name,
		},
	]);
</script>

<style lang="scss" scoped>
	@import "../../storybook/src/assets/css/mixins";

	.u-topic {
		&__head {
			padding: var(--space-7);
		}

		&__headline {
			font-size: var(--font-size-8) !important;

			@include desktop {
				font-size: var(--font-size-10) !important;
				line-height: var(--line-height-sm) !important;
			}
		}
	}
</style>
